import React from 'react'
import LoginSingUp from './components/LoginSingUp'

const User = () => {
  return (
    <>
    <LoginSingUp />
    </>
  )
}

export default User